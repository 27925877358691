<template>
  <div class="header-container">
    <header class="header">
      <img class="logo" src="../assets/imgs/logo.png" alt="ServiCash" />
      <div class="menu">
        <div class="menu-item" :class="{ active: ['/'].indexOf($route.path) !== -1}"   @click="itemFn('/')">
          Inicio
          <img class="img" src="../assets/imgs/icon.png" alt="" />
        </div>
        <div class="menu-item" :class="{ active: ['/about','/blog'].indexOf($route.path) !== -1}"  @click="itemFn('/about')">
          Sobre nosotros
          <img class="img" src="../assets/imgs/icon.png" alt="" />
          <div class="pop">
            <div class="pop-content">
              <div class="pop-item" @click.stop="itemFn('/blog')">Blog</div>
              <div class="pop-item" @click.stop="itemFn('/join')">Reclutamiento</div>
            </div>
          </div>
        </div>
        <div class="menu-item" :class="{ active: ['/privacidad', '/safe','/qa'].indexOf($route.path) !== -1}"  @click.stop="itemFn('/safe')">
          Seguridad
          <img class="img" src="../assets/imgs/icon.png" alt="" />
          <div class="pop" style="width: 180px;">
            <div class="pop-content">
              <div class="pop-item" @click.stop="itemFn('/qa')">Centro de ayuda</div>
              <div class="pop-item" @click.stop="itemFn('/privacidad')">Política de Privacidad</div>
            </div>
          </div>
        </div>
      </div>
      <img class="download" @click="downloadApp" src="../assets//imgs/download.png" alt="" />
    </header>
  </div>
</template>
<script>
export default {
  name: 'HeaderC',
  data () {
    return {
      acitve: 0
    }
  },
  props: {
    type: {
      type: String
    }
  },

  created () {},
  methods: {
    itemFn (path) {
      if (path === this.$route.path) {
        return
      }
      if (path) {
        this.$router.push(path)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container {
  height: 82px;
}
.header {
  background: #fff;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  padding: 0 120px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  .logo {
    width: 144px;
    height: 34px;
  }
  .download {
    height: 50px;
  }
  .menu {
    display: flex;
    .menu-item {
      width: 110px;
      margin-right: 32px;
      color: #1a5c4f;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      position: relative;
      line-height: 32px;
      cursor: pointer;
      .img {
        display: none;
        width: 34px;
        height: 4px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
      &.active {
        .img {
          display: block;
        }
      }
      .pop {
        display: none;
        position: absolute;
        top: 100%;
        left: -5px;
        width: 120px;
        padding-top: 35px;
        .pop-content {
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
          padding: 12px;
          .pop-item {
            color: #1a5c4f;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 12px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      &:hover{
        .pop{
          display: block;
        }
      }
    }
  }
}
</style>
