<template>
  <div class="footer" id="footer">
    <div class="footer-info">
      <div class="footer__icon">
        <img src="@/assets/imgs/logo.png" />
        <div class="footer__google" @click="download"></div>
      </div>
      <div class="footer__content">
        <!-- 第1列 -->
        <div class="column">
          <div class="column-item">
            <div class="column-item__title">Centro de ayuda</div>
            <div class="column-item__label">
              <div class="column-item__label__icon document-icon"></div>
              <div class="column-item__label__text" @click="qa" style="cursor: pointer;">Preguntas frecuentes</div>
            </div>
          </div>
          <div class="column-item">
            <div class="column-item__title">Horario de servicio</div>
            <div class="column-item__label">
              <div class="column-item__label__icon clock-icon"></div>
              <div class="column-item__label__text">Lunes – Sábado 9:00-18:00</div>
            </div>
          </div>
        </div>
        <!-- 第2列 -->
        <div class="column">
          <div class="column-item">
            <div class="column-item__title">Contáctenos</div>
            <div class="column-item__label">
              <div class="column-item__label__icon phone-icon"></div>
              <div class="column-item__label__text">Línea de atención al cliente: {{appInfo.servicePhoneNo}}</div>
            </div>
            <div class="column-item__label">
              <div class="column-item__label__icon phone-icon"></div>
              <div class="column-item__label__text">Línea directa de quejas: {{appInfo.servicePhoneNo}}</div>
            </div>
            <div class="column-item__label">
              <div class="column-item__label__icon email-icon"></div>
              <div class="column-item__label__text"> {{appInfo.serviceEmail}}</div>
            </div>
          </div>
          <!-- <div class="column-item">
            <div class="column-item__title">Únase a nosotros</div>
            <div class="column-item__label">
              <div class="column-item__label__icon pack-icon"></div>
              <div class="column-item__label__text" @click="joinUs" style="cursor: pointer;">Reclutamiento social</div>
            </div>
          </div> -->
        </div>
        <!-- 第3列 -->
        <div class="column">
          <div class="column-item">
            <div class="column-item__title">Sociedad</div>
            <div class="column-item__label">
              <div class="column-item__label__icon building-icon"></div>
              <div class="column-item__label__text">BBO Consultores, S.A. de C.V.</div>
            </div>
            <div class="column-item__label">
              <div class="column-item__label__icon location-icon"></div>
              <div class="column-item__label__text">CALLE EUCALIPTOS, 504D, REFORMA, OAXACA, JUAREZ, OAXACA, 68050</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-tip">
      Todos los derechos reservados © 2024 ServiCash
    </div>
  </div>
</template>

<script>
import { queryAppInfo } from '@/api/index'
export default {
  name: 'FooterNew',
  data () {
    return {
      acitve: 1,
      appInfo: {}
    }
  },
  props: {
    type: {
      type: String
    }
  },
  created () {
    queryAppInfo({
      req: {
        appCode: 'ServiCash'
      }
    }).then((res) => {
      if (res.status === 1) {
        this.appInfo = res.payload || {}
        console.log(this.appInfo)
      }
    })
  },
  methods: {
    download () {
      this.downloadApp()
    },
    goTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    toNexPage (path) {
      this.$router.push(path)
    },
    joinUs () {
      this.$router.push('/joinUs')
    },
    qa () {
      this.$router.push('/qa')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 64px 120px 32px;
  width: 100%;
  background-color: #171717;
  &-info {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__icon {
    display: flex;
    flex-direction: column;
    img {
      width: 179px;
      height: 38px;
    }
  }
  &__content {
    color: #fff;
    display: flex;
    .column {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 326px;
      text-align: left;
      &-item {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0;
        }
        &__title {
          font-size: 16px;
          font-weight: 550;
          line-height: 22px;
          margin-bottom: 16px;
        }
        &__label {
          display: flex;
          color: #E6E6EE;
          font-size: 14px;
          margin-bottom: 16px;
          line-height: 24px;
          &:last-child {
            margin-bottom: 0;
          }
          &__text {
            max-width: 266px;
          }
          &__icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
      .document-icon {
        background-image: url('../assets/imgs/footer/document-icon.png');
      }
      .clock-icon {
        background-image: url('../assets/imgs/footer/clock-icon.png');
      }
      .phone-icon {
        background-image: url('../assets/imgs/footer/phone-icon.png');
      }
      .email-icon {
        background-image: url('../assets/imgs/footer/email-icon.png');
      }
      .pack-icon {
        background-image: url('../assets/imgs/footer/pack-icon.png');
      }
      .building-icon {
        background-image: url('../assets/imgs/footer/building-icon.png');
      }
      .location-icon {
        background-image: url('../assets/imgs/footer/location-icon.png');
      }
    }
  }
  &__google {
    display: inline-block;
    width: 107px;
    height: 35px;
    margin-top: 32px;
    background: url('../assets/imgs/download-b.png') no-repeat;
    background-size: contain;
    background-size: 100%;
    cursor: pointer;
  }
  &-tip {
    color: #E6E6EE;
    text-align: center;
    font-size: 14px;
    padding-top: 24px;
    border-top: 1px solid #E6E6EE;
    width: 100%;
    margin: 64px auto 0;
  }
}
</style>
