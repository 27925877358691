import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/BlogView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import(/* webpackChunkName: "join" */ '../views/JoinView.vue')
  },
  {
    path: '/safe',
    name: 'safe',
    component: () => import(/* webpackChunkName: "safe" */ '../views/SafeView.vue')
  },
  {
    path: '/qa',
    name: 'qa',
    component: () => import(/* webpackChunkName: "qa" */ '../views/QaView.vue')
  },
  {
    path: '/privacidad',
    name: 'privacidad',
    component: () => import(/* webpackChunkName: "privacidad" */ '../views/PrivacidadView.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
