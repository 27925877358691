
import axios from 'axios'
const baseURI = `${process.env.VUE_APP_BASE_API}/`
axios.defaults.baseURL = baseURI
axios.defaults.timeout = 60000

function appJson (config) {
  // const data = config.data
  // data.token = WIMI.session.get(WIMI.session.token)
  //   ? WIMI.session.get(WIMI.session.token)
  //   : ''
  // data.orgCode = WIMI.session.get(WIMI.session.org)
  //   ? WIMI.session.get(WIMI.session.org)
  //   : ''
  // data.orgSource = WIMI.session.get(WIMI.session.app)
  //   ? WIMI.session.get(WIMI.session.app)
  //   : ''
  // console.log('加密前参数', config.data)
  // if (
  //   encryptionWhiteString.indexOf(config.url.replace(config.baseURL, '')) === -1
  // ) {
  //   config.data = encrypt(config.data || {})
  // }
  return config
}

axios.interceptors.request.use(
  config => {
    config['Content-Type'] = 'application/json'

    config = appJson(config)
    // if (config.headers.post['Content-Type'] === 'application/json') {
    //   config = appJson(config)
    // } else {
    //   config = mergeToken(config)
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

const ajax = {
  host: location.origin,
  baseURI: baseURI,

  post (url, data = {}, options = {}) {
    const obj = {
      method: 'post',
      url,
      data: data
    }
    Object.assign(obj, options)
    return axios(obj)
  },
  get (url, params = {}, options = {}) {
    const obj = {
      method: 'get',
      url,
      params
    }
    Object.assign(obj, options)
    return axios(obj)
  }
}
export default ajax
