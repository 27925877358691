/**
 * 通用接口
 */
import axios from './axios'
//
export const queryContractTemplateList = (data) => {
  return axios.post('/app/contractTemplate/queryContractTemplateList', data)
}

export const queryAppInfo = (data) => {
  return axios.post('/app/appInfo/queryAppInfo', data)
}
